import LocomotiveScroll from 'locomotive-scroll';
import gsap from 'gsap';
import barba from '@barba/core';
import Preload from 'preload-it';


export default function initHome() {


  const drag = document.querySelector('.drag');
  const left = document.querySelector('.home__slider__item.left');
  let mouse_is_down = false;
  let current = window.innerWidth / 2;
  const slider = document.querySelector('.home__slider');
  const left_section = document.querySelector('.home__slider__item.left');
  const left_content = document.querySelector('.home__slider__item.left .home__slider__item__content');
  const right_section = document.querySelector('.home__slider__item.right');
  const right_content = document.querySelector('.home__slider__item.right .home__slider__item__content');
  const s_link = document.querySelector('.s-link');
  let half = window.innerWidth / 2;
  const intro_video = document.getElementById('video-intro');

  if(intro_video) {

    initImageSequence();

    const p = Preload();
    let assets = [];
    let introAnimated = false;

    assets = assets.concat(images_right);
    assets = assets.concat(images_left);

    if(window.innerWidth <= 991) {
      intro_video.remove();
      assets.push('img/seq-mobile-left/reveal-card(1).jpg');
      intro_video.poster = 'img/seq-mobile-left/reveal-card(1).jpg';
      document.querySelector('.home__slider__item.left .home__slider__item__content h3').innerText = 'Built around you';
    } else {
      intro_video.querySelector('source').setAttribute('src', 'video/reveal-opti.mp4');
      intro_video.load();
      assets.push('video/reveal-opti.mp4');
    }

    function progressIntro() {
      let progress = intro_video.currentTime / intro_video.duration;

      if(progress > 0.3 && !introAnimated) {
        introAnimated = true;

        document.querySelectorAll('.home__slider__item__content').forEach((item) => {
          item.classList.add('animate');
        });
      }


      if(progress == 1) {
        canvas_left.style.display = 'block';
        intro_video.remove();
        s_link.classList.add('animate');
      }
    }

    if (window.innerWidth >= 992) {
      intro_video.ontimeupdate = function() {progressIntro()};
    } else {
      document.querySelectorAll('.home__slider__item__content').forEach((item) => {
        item.classList.add('animate');
      });
      canvas_left.style.display = 'block';
      s_link.classList.add('animate');
    }

    const loader_progress = document.querySelector('#loader .progress span');

    p.onprogress = event => {
      loader_progress.style.transform = 'scaleX(' + event.progress/100 + ')';
    }

    p.fetch(assets).then(items => {

      document.querySelector('#loader .progress').classList.add('animate');
      document.querySelector('#loader').classList.add('loaded');

      if (window.innerWidth >= 992) {
        setTimeout(function() {
          intro_video.play();
        }, 600);
      }
    })
  } else {
    setTimeout(function() {
      document.querySelectorAll('.home__slider__item__content').forEach((item) => {
        item.classList.add('animate');
      });
    }, 100);
  }



  drag.addEventListener('mouseup', dragUp);
  drag.addEventListener('touchend', dragUp);

  function dragUp() {
    updateSlider();

    mouse_is_down = false;
    document.querySelector('.drag-me').classList.remove('dragging');
  }





  function updateSlider() {
    // Left bigger
    if(current > half) {

      if(current < 0.9 * window.innerWidth) {
        resetSlider(0.6);
      } else {
        barba.go('/card');
      }
    }
    else {
      // Right bigger
      if(current > 0.1 * window.innerWidth) {
        resetSlider(0.6);
      } else {
        barba.go('/app');
      }
    }
  }


  function resetSlider(delay) {
    current = half;

    if(current_frame_right > 0) {
      backwardsRightID = requestAnimationFrame(backwards);
    }

    if(current_frame_left > 0) {
      backwardsLeftID = requestAnimationFrame(backwards);
    }

    gsap.to(left_section, {
      width: current + "px",
      duration: 1,
      ease: "power1.inOut"
    });

    gsap.to(drag, {
      left: current + "px",
      duration: 0.4,
      ease: "power1.inOut"
    });

    gsap.to(left_content, {
      opacity: 1,
      duration: 0.8,
      delay: delay,
      ease: "expo.out"
    });

    gsap.to(right_content, {
      opacity: 1,
      duration: 0.8,
      delay: delay,
      ease: "expo.out"
    });
  }








  function windowResize() {
    if(document.querySelector('.home__slider__item.left.active')) {
      left_section.style.width = window.innerWidth + "px";
    }
    else if(document.querySelector('.home__slider__item.right.active')) {
      left_section.style.width = "0px";
    }

    if(document.querySelector('.home__slider')) {
      initImageSequence();
    }

  }

  window.addEventListener('resize', windowResize);



}


