import LocomotiveScroll from 'locomotive-scroll';
import gsap from 'gsap';
import barba from '@barba/core';

export default function initCard() {
  initExpands();
  initSliders();
  initMotions();

  const s_link = document.querySelector('.s-link');
  const header = document.querySelector('.header');

  if (document.querySelector('#loader')) {
    const loader_progress = document.querySelector('#loader .progress span');
    loader_progress.style.transform = 'scaleX(1)';
    document.querySelector('#loader .progress').classList.add('animate');
    document.querySelector('#loader').classList.add('loaded');
  }

  scroller_section = new LocomotiveScroll({
    el: document.querySelector('.section-scroll'),
    smooth: true,
    touchMultiplier: 4,
    scrollbarClass: 's-scrollbar',
  });


  setTimeout(() => {
    document.querySelector('.section__head h1').classList.add('animate');
    scroller_section.update();
  }, 200);

  const scene_video = document.querySelector('.section-card__scene video');
  const radial = document.querySelector('.section-card__radial');
  const radial_video = radial.querySelector('video');

  let radialAnimated = false;
  let sceneAnimated = false;

  const card_scroll = localStorage.getItem('cardScroll');
  if (card_scroll > 0) {
    scroller_section.scrollTo(parseInt(card_scroll), {
      duration: 0,
    });
  }

  const h2_one = document.querySelector('.section-card__scene h2 span:first-child');
  const h2_two = document.querySelector('.section-card__scene h2 span:last-child');

  const off1 = document.querySelector('.section-card__feature-block').getBoundingClientRect().bottom;
  const off2 = document.querySelector('.section-card__feature').getBoundingClientRect().top;
  const off3 = document.querySelector('.section-card__radial').getBoundingClientRect().top;


  scroller_section.on('scroll', (args) => {
    const s = args.scroll.y;
    localStorage.setItem('cardScroll', parseInt(s));
    if (s <= off1) {
      header.classList.remove('black');
    } else if (s < off2) {
      header.classList.add('black');
    } else if (s < off3) {
      header.classList.remove('black');
    } else {
      header.classList.add('black');
    }

    if (typeof args.currentElements['card-scene'] === 'object') {
      const { progress } = args.currentElements['card-scene'];
      if (!sceneAnimated && progress > 0.3) {
        sceneAnimated = true;
        scene_video.play();
        document.querySelector('.section-card__scene .tiles').classList.add('animate');
      }

      if (window.innerWidth > 1024) {
        h2_one.style.transform = `translateX(${progress * 28}%)`;
        h2_two.style.transform = `translateX(-${progress * 28}%)`;
      }
    }

    if (s >= (document.querySelector('.footer').offsetTop - 200)) {
      header.classList.add('hide');
    } else if (document.querySelector('.expanded') == null) {
      header.classList.remove('hide');
    }
    if(typeof args.currentElements['radial'] === 'object') {
      let progress = args.currentElements['radial'].progress;

      if(!radialAnimated && progress > 0.3) {
        radialAnimated = true;
        radial.classList.add('animate');
        radial_video.play();

        setTimeout(function() {
          radial.classList.add('final');
        }, 2000);
      }
    }
  });

  const close_section_left = document.querySelector('.section__close');
  close_section_left.addEventListener('mousedown', () => {
    barba.go('/');
  });




  const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

  // Gets the mouse position
  const getMousePos = e => {
    return {
      x : e.clientX,
      y : e.clientY
    };
  };

  const calcWinsize = () => {
    return {width: window.innerWidth, height: window.innerHeight};
  };


  let winsize = calcWinsize();

  // Linear interpolation
  const lerp = (a, b, n) => (1 - n) * a + n * b;

  // Map number x from range [a, b] to [c, d]
  const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;

  // Track the mouse position
  let mousepos = {x: winsize.width/2, y: winsize.height/2};

  if(window.innerWidth > 991) {
    window.addEventListener('mousemove', ev => mousepos = getMousePos(ev));
  }

  function move(el) {
    let translationVals = {tx: 0, ty: 0};
    // get random start and end movement boundaries
    const xstart = getRandomNumber(5,40);
    const ystart = getRandomNumber(5,40);

    // infinite loop
    const render = () => {
      // Calculate the amount to move.
      // Using linear interpolation to smooth things out.
      // Translation values will be in the range of [-start, start] for a cursor movement from 0 to the window's width/height
      translationVals.tx = lerp(translationVals.tx, map(mousepos.x, 0, winsize.width, -xstart, xstart), 0.07);
      translationVals.ty = lerp(translationVals.ty, map(mousepos.y, 0, winsize.height, -ystart, ystart), 0.07);

      let newScale = 1.2;
      if(el.classList.contains('img-container-anim')) newScale = 1;

      gsap.set(el, {x: translationVals.tx, y: translationVals.ty, scale: newScale});
      requestAnimationFrame(render);
    }
    requestAnimationFrame(render);
  }

  if(window.innerWidth > 991) {
    const imagesAnim = document.querySelectorAll('.img-anim');
    const imagesContainerAnim = document.querySelectorAll('.img-container-anim');

    imagesAnim.forEach((img) => {
      move(img);
    })
    imagesContainerAnim.forEach((img) => {
      move(img);
    })
  }


}
