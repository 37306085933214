import gsap from 'gsap';
import LocomotiveScroll from 'locomotive-scroll';

// import Swiper JS
import Swiper, { Autoplay } from 'swiper';

// import styles
import 'swiper/swiper.scss';


let titles = [];
let btns = [];
let current = 0;


function animateTitle() {

  setTimeout(function() {
    titles[current].classList.add('animate');
    btns[current].classList.add('animate');
  }, 600);
}

export default function initSlider() {
  const slider_imgs = document.querySelectorAll('.slider-imgs');

  slider_imgs.forEach((slider) => {



    if(slider.classList.contains('inverse')) {
      let newH = slider.dataset.height;

      if(window.innerWidth <= 991) {
        newH = slider.dataset.heightMobile;
      }

      newH = parseInt(slider.querySelector('.slider-imgs__item.active').dataset.id) * newH;

      slider.querySelector('.sliderr').style.transition = 'none';
      slider.querySelector('.sliderr').style.transform = 'translate3d(0, -' + newH + 'vh, 0)';
      setTimeout(function() {
        slider.querySelector('.sliderr').style.transition = '';
      }, 1000);
    }


    slider.addEventListener('click', function() {

      slider_imgs.forEach((s) => {
        const w = s.querySelector('.sliderr');
        let h = s.dataset.height;
        let i;

        if(window.innerWidth <= 991) {
          h = s.dataset.heightMobile;
        }

        const active = s.querySelector('.slider-imgs__item.active');


        if(s.classList.contains('inverse')) {
          if(active.previousElementSibling) {
            active.classList.remove('active');
            active.previousElementSibling.classList.add('active');
            i = active.previousElementSibling.dataset.id;
          } else {
            active.classList.remove('active');
            let el = s.querySelector('.slider-imgs__item:last-child');
            el.classList.add('active');
            i = el.dataset.id;
          }
        } else {
          if(active.nextElementSibling) {
            active.classList.remove('active');
            active.nextElementSibling.classList.add('active');
            i = active.nextElementSibling.dataset.id;
          } else {
            active.classList.remove('active');
            let el = s.querySelector('.slider-imgs__item:first-child');
            el.classList.add('active');
            i = el.dataset.id;
          }
        }

        w.style.transform = 'translate3d(0, -' + (i * h) + 'vh, 0)';

      });


    });
  });

  if(!document.querySelector('.slider')) {
    return false;
  }

  document.querySelector('.page--slider').classList.add('animate');

  titles = document.querySelectorAll('.slider__titles .split-slider');
  btns = document.querySelectorAll('.slider__titles .btn-cta');

  const content = document.querySelector('.slider__content');
  const titles_container = document.querySelector('.slider__titles');
  const items = document.querySelectorAll('.slider__content__item');

  const title_right = document.querySelector('.slider__right__title');

  let scroller = null;

  const header = document.querySelector('.header');

  header.classList.add('black');

  const next = document.querySelector('.slider__nav.next');
  const prev = document.querySelector('.slider__nav.prev');

  const pages = document.querySelectorAll('.slider__pagination__item');
  const pagination = document.querySelector('.slider__pagination');


  // animate first title
  setTimeout(function() {
    animateTitle();
  }, 200);

  gsap.to(prev, {
    opacity: '1',
    visibility: 'visible',
    transform: 'translate3d(0, -50%, 0)',
    delay: 2,
    duration: .6,
    ease: "expo.out"
  });

  gsap.to(next, {
    opacity: '1',
    visibility: 'visible',
    transform: 'translate3d(0, -50%, 0)',
    delay: 2,
    duration: .6,
    ease: "expo.out"
  });

  // carousels
  const sliderLeft = new Swiper('.slider__left .slider-imgs', {
    modules: [Autoplay],
    loop: true,
    simulateTouch: false,
    autoplay: {
      delay: 6000
    },
    speed: 1200
  });

  const sliderRight = new Swiper('.slider__right .slider-imgs', {
    loop: true,
    simulateTouch: false
  });


  sliderLeft.on('autoplay', function(e) {
    sliderRight.slideNext(1200);
  });


  sliderLeft.on('slideChangeTransitionStart', function(e) {
    
    document.querySelector('.slider__pagination__item.active').classList.remove('active');
    titles[current].classList.remove('animate');
    btns[current].classList.remove('animate');
    current = sliderLeft.realIndex;

    setTimeout(function() {
      titles[current].classList.add('animate');
      btns[current].classList.add('animate');
      pages[current].classList.add('active');
    }, 400);

  });


  next.addEventListener('click', function() {
    sliderLeft.slideNext(1200);
    sliderRight.slideNext(1200);
    sliderLeft.autoplay.start();
  });
  prev.addEventListener('click', function() {
    sliderLeft.slidePrev(1200);
    sliderRight.slidePrev(1200);
    sliderLeft.autoplay.start();
  });


  if(window.location.hash) {
    if(window.location.hash == '#travel') {
      sliderLeft.slideTo(2, 1200);
      sliderRight.slideTo(2, 1200);
    }
  }


  // btns
  btns.forEach((btn) => {

    if(window.innerWidth > 991) {
      btn.addEventListener('mouseenter', function(e) {
        document.querySelector('.slider__right .swiper-slide-active').classList.add('hover');
        title_right.innerText = this.dataset.text;
        title_right.classList.add('show');
        sliderLeft.autoplay.stop();
        document.querySelector('.slider__pagination__item.active .slider__pagination__item__progress').style.animation = 'none';
      });

      btn.addEventListener('mouseleave', function(e) {
        document.querySelector('.slider__right .swiper-slide-active').classList.remove('hover');
        title_right.classList.remove('show');
        if(!titles_container.classList.contains('hide')) sliderLeft.autoplay.start();
        document.querySelector('.slider__pagination__item.active .slider__pagination__item__progress').style.animation = '';
      });
    }


    btn.addEventListener('click', function(e) {
      e.preventDefault();

      header.classList.add('hide');

      sliderLeft.autoplay.stop();

      title_right.classList.remove('show');

      titles_container.classList.add('hide');
      pagination.classList.add('hide');

      if(window.innerWidth > 991) document.querySelector('.slider__right .swiper-slide-active .transition').classList.add('show');

      var rect = items[current].querySelector('.item:first-child').getBoundingClientRect();
      var yTotal = parseFloat(rect.y + rect.height);

      gsap.to(document.querySelector('.slider__right .swiper-slide-active'), {
        clipPath: 'polygon(10% ' + rect.y + 'px, 46% ' + rect.y + 'px, 46% ' + 61.8 + '%, 10% ' + 61.8 + '%)',
        duration: .2,
        onComplete: function() {
          
          if(window.innerWidth > 991) {

            setTimeout(function() {
              content.classList.add('show');
              items[current].classList.add('animate');
              items[current].querySelector('h2').classList.add('animate');
              items[current].querySelector('.items').classList.add('animate');
              document.querySelector('.slider__right .swiper-slide-active .transition').classList.remove('show');
            }, 800);
            
            document.querySelector('.slider__right .swiper-slide-active').classList.remove('hover');
          } else {
            content.classList.add('show');
            items[current].classList.add('animate');
            items[current].querySelector('h2').classList.add('animate');
            items[current].querySelector('.items').classList.add('animate');
          }


          if(scroller) scroller.destroy();

          scroller = new LocomotiveScroll({
            el: items[current],
            smooth: true,
            touchMultiplier: 3,
          });
        }
      });

      gsap.to(prev, {
        opacity: '0',
        visibility: 'hidden',
        transform: 'translate3d(-100%, -50%, 0)',
        duration: .6,
        ease: "expo.out"
      });
    });
  });


  // close
  const close = document.querySelector('.slider__content__close');

  close.addEventListener('click', function() {
    items[current].classList.remove('animate');
    items[current].querySelector('h2').classList.remove('animate');
    items[current].querySelector('.items').classList.remove('animate');

    header.classList.remove('hide');

    if(window.innerWidth > 991) document.querySelector('.slider__right .swiper-slide-active').style.clipPath = '';

    sliderLeft.autoplay.start();

    setTimeout(function() {
      content.classList.remove('show');
    }, 300);

    setTimeout(function() {
      titles_container.classList.remove('hide');
      pagination.classList.remove('hide');
    }, 600);

    if(scroller) scroller.destroy();

    gsap.to(prev, {
      opacity: '1',
      visibility: 'visible',
      transform: 'translate3d(0, -50%, 0)',
      duration: .6,
      delay: 1,
      ease: "expo.out"
    });
  });

}