function initSplitChars() {
  const els = document.querySelectorAll('.split-chars');
  
  if(els) {
    els.forEach((el) => {
      var chars = el.textContent.split("");

      el.innerHTML = '';

      for (var i=0; i<chars.length; i++) {
        var char = document.createElement('span');
        char.innerText = chars[i];

        el.appendChild(char);
      }
    });
  }
}



function initSplit() {
  const els = document.querySelectorAll('.split');

  if(els) {
    els.forEach((el) => {
      var words = el.textContent.split(' ');

      el.innerHTML = '';

      for (var i=0; i<words.length; i++) {
        var span = document.createElement('span');

        var word = document.createElement('span');
        word.innerText = words[i];

        span.appendChild(word);

        el.appendChild(span);
      }
    });
  }
}



function initSplitSlider() {
  const els = document.querySelectorAll('.split-slider');

  if(els) {
    els.forEach((el) => {
      var chars = el.innerText.split("");

      el.innerHTML = '';

      for (var i=0; i<chars.length; i++) {
        var char = document.createElement('span');
        char.innerText = chars[i];

        el.appendChild(char);
      }
    });
  }
}




export default function initTypography() {

  initSplit();
  initSplitChars();
  initSplitSlider();

}