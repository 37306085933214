import LocomotiveScroll from 'locomotive-scroll';
import gsap from 'gsap';
import barba from '@barba/core';

export default function initApp() {

  initExpands();
  initSliders();
  initMotions();

  const s_link = document.querySelector('.s-link');
  const span_app = document.querySelector('.header .app');
  const header = document.querySelector('.header');

  header.classList.add('black');
  s_link.classList.add('animate');
  s_link.classList.add('black');

  const loader_progress = document.querySelector('#loader .progress span');
  loader_progress.style.transform = 'scaleX(1)';
  document.querySelector('#loader .progress').classList.add('animate');
  document.querySelector('#loader').classList.add('loaded');



  scroller_section = new LocomotiveScroll({
    el: document.querySelector('.section-scroll'),
    smooth: true,
    touchMultiplier: 4,
    scrollbarClass: 's-scrollbar'
  });

  setTimeout(function() {
    document.querySelector('.section__head h1').classList.add('animate');
    scroller_section.update();
  }, 200);
	
  span_app.classList.add('show');


  const phone_video = document.querySelector('.section-app__phone video');

  const radial = document.querySelector('.section-app__radial');
  const radial_video = radial.querySelector('video');

  const built_video = document.querySelector('.section-app__rotate video');
  const scene_video = document.querySelector('.section-app__scene video');

  let phoneAnimated = false;
  let radialAnimated = false;
  let builtAnimated = false;
  let sceneAnimated = false;

  canvas_right.style.display = 'block';
  canvas_left.style.display = 'none';

  let app_scroll = localStorage.getItem('appScroll');

  if(app_scroll > 0) scroller_section.scrollTo(parseInt(app_scroll), {
    duration: 0
  });

  const h2_one = document.querySelector('.section-app__scene h2 span:first-child');
  const h2_two = document.querySelector('.section-app__scene h2 span:last-child');

  scroller_section.on('scroll', (args) => {
    var s = args.scroll.y;
    localStorage.setItem('appScroll', parseInt(s));

    if (s < (window.innerHeight/2)) {
      document.querySelector('.section__close').classList.remove('black');
    } else {
      document.querySelector('.section__close').classList.add('black');
    }

    if(typeof args.currentElements['phone'] === 'object') {
      let progress = args.currentElements['phone'].progress;

      if(!phoneAnimated && progress > 0.3) {
        phoneAnimated = true;
        phone_video.play();
      }
    }

    if(typeof args.currentElements['built'] === 'object') {
      let progress = args.currentElements['built'].progress;

      if(!builtAnimated && progress > 0.3) {
        builtAnimated = true;
        built_video.play();
      }
    }

    if(typeof args.currentElements['app-scene'] === 'object') {
      let progress = args.currentElements['app-scene'].progress;

      if(!sceneAnimated && progress > 0.3) {
        sceneAnimated = true;
        scene_video.play();
        document.querySelector('.section-app__scene .tiles').classList.add('animate');
      }

      if(window.innerWidth > 1024) {
        h2_one.style.transform = 'translateX(' + progress * 100 + '%)';
        h2_two.style.transform = 'translateX(-' + progress * 100 + '%)';
      }
    }

    if (s >= (document.querySelector('.footer').offsetTop - 200)) {
      header.classList.add('hide');
    } else if(document.querySelector('.expanded') == null) {
      header.classList.remove('hide');
    }

    if(typeof args.currentElements['app-radial'] === 'object') {
      let progress = args.currentElements['app-radial'].progress;

      if(!radialAnimated && progress > 0.3) {
        radialAnimated = true;
        radial.classList.add('animate');
        radial_video.play();

        setTimeout(function() {
          radial.classList.add('final');
        }, 2000);
      }
    }

  });




  const close_section_right = document.querySelector('.section__close');
  close_section_right.addEventListener('mousedown', function() {
    barba.go('/');
  });
  

}