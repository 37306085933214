window.scroller = null;

// components
import { initCursor, initHovers } from './components/cursor';
import LocomotiveScroll from 'locomotive-scroll';
import initHome from './components/home';
import initCard from './components/card';
import initApp from './components/app';
import initTypography from './components/typo';
import initSignup from './components/signup';
import initSlider from './components/slider';
import barba from '@barba/core';
import gsap from 'gsap';

// Import styles
import '../scss/styles.scss';

function initCTAS() {
  localStorage.setItem('cardScroll', 0);
  if(window.innerWidth <= 991) {
    return false;
  }

  var ELEMENTS = document.querySelectorAll('.btn-secondary');
  var ELEMENTS_SPAN = [];

  ELEMENTS.forEach(function(element, index) {

    if(element.classList.contains('noanim')) return false;

    if (!ELEMENTS_SPAN[index])
      ELEMENTS_SPAN[index] = element.querySelector('.cta__anim');

    element.addEventListener("mouseover", function(e) {

      var offset = ELEMENTS_SPAN[index].parentElement.getBoundingClientRect();
      var top = offset.top;
      var left = offset.left;

      ELEMENTS_SPAN[index].style.left = e.pageX - left + "px";
      ELEMENTS_SPAN[index].style.top = e.pageY - top + "px";
    });

    element.addEventListener("mouseout", function(e) {
      var offset = ELEMENTS_SPAN[index].parentElement.getBoundingClientRect();
      var top = offset.top;
      var left = offset.left;

      ELEMENTS_SPAN[index].style.left = e.pageX - left + "px";
      ELEMENTS_SPAN[index].style.top = e.pageY - top + "px";
    });
  });
}




const s_link = document.querySelector('.s-link');


window.initImageSequence = function() {

  window.images_left = [];
  window.frame_count_left = 72;

  window.folder_left = 'seq-desktop-left';
  let format = 'jpg';

  if(window.innerWidth <= 991) {
    format = 'jpg';
    window.folder_left = 'seq-mobile-left';
  }

  for (let i = 1; i <= frame_count_left; i++) {
    images_left.push(`./img/` + window.folder_left + `/reveal-card(` + i + `).` + format)
  }

  window.images_right = [];
  window.frame_count_right = 72;

  window.folder_right = 'seq-desktop-right';
  if(window.innerWidth <= 991) {
    window.folder_right = 'seq-mobile-right';
  }

  for (let i = 1; i <= frame_count_right; i++) {
    images_right.push(`./img/` + window.folder_right + `/reveal_phone(` + i + `).` + format)
  }

  window.current_frame_left = 1;
  window.canvas_left = document.getElementById('sequence-left');
  const context_left = canvas_left.getContext("2d");
  context_left.imageSmoothingEnabled = false;

  const currentFrameLeft = index => (
      `./img/` + folder_left + `/reveal-card(${index}).` + format
  )

  const img_left = new Image()
  img_left.src = currentFrameLeft(1);
  canvas_left.width = window.innerWidth;
  canvas_left.height = window.innerHeight;

  var scaleLeft, xLeft, yLeft;


  img_left.onload=function(){

    scaleLeft = Math.max(canvas_left.width / img_left.width, canvas_left.height / img_left.height);
    xLeft = (canvas_left.width / 2) - (img_left.width / 2) * scaleLeft;
    yLeft = (canvas_left.height / 2) - (img_left.height / 2) * scaleLeft;
    context_left.imageSmoothingEnabled = false;
    context_left.drawImage(img_left, xLeft, yLeft, img_left.width  * scaleLeft, img_left.height  * scaleLeft);
  }


  window.updateImageLeft = index => {
    current_frame_left = index;
    img_left.src = currentFrameLeft(index);

    context_left.drawImage(img_left, xLeft, yLeft, img_left.width  * scaleLeft, img_left.height  * scaleLeft);
  }


  window.current_frame_right = 1;
  window.canvas_right = document.getElementById('sequence-right');
  const context_right = canvas_right.getContext("2d");

  const currentFrameRight = index => (
      `./img/` + folder_right + `/reveal_phone(${index}).` + format
  )


  const img_right = new Image()
  img_right.src = currentFrameRight(1);
  canvas_right.width = window.innerWidth;
  canvas_right.height = window.innerHeight;

  var scaleRight, xRight, yRight;

  img_right.onload=function(){

    scaleRight = Math.max(canvas_right.width / img_right.width, canvas_right.height / img_right.height);
    xRight = (canvas_right.width / 2) - (img_right.width / 2) * scaleRight;
    yRight = (canvas_right.height / 2) - (img_right.height / 2) * scaleRight;

    context_right.drawImage(img_right, xRight, yRight, img_right.width  * scaleRight, img_right.height  * scaleRight);
  }

  window.updateImageRight = index => {
    current_frame_right = index;
    img_right.src = currentFrameRight(index);
    context_right.imageSmoothingEnabled = false;

    context_right.drawImage(img_right, xRight, yRight, img_right.width  * scaleRight, img_right.height  * scaleRight);
  }



  let images_sequence = [];
  images_sequence = images_sequence.concat(images_right);
  images_sequence = images_sequence.concat(images_left);


  function preloadImages(images) {
    for (var i = 0; i < images.length; i++) {
      let l = document.createElement('link')
      l.rel = 'prefetch'
      l.as = 'image'
      l.href = images[i]
      document.head.appendChild(l)
    }
  }

  preloadImages(images_sequence);






  window.backwardsLeftID = null;
  window.backwardsRightID = null;
  window.forwardsLeftID = null;
  window.forwardsRightID = null;



}

window.backwards = function() {
  if(document.querySelector('.step1')) {
    document.querySelector('.step1').style.opacity = 0;
    document.querySelector('.step2').style.opacity = 0;
  }

  if(current_frame_left- 1 > 1) {
    current_frame_left--;
    updateImageLeft(current_frame_left);

    backwardsLeftID = requestAnimationFrame(backwards);
  } else {
    cancelAnimationFrame(backwardsLeftID);
  }

  if(current_frame_right - 1 > 1) {
    current_frame_right--;
    updateImageRight(current_frame_right);

    backwardsRightID = requestAnimationFrame(backwards);
  } else {
    cancelAnimationFrame(backwardsRightID);
  }
}



function forwardsLeft() {
  if(current_frame_left + 1 < frame_count_left) {
    current_frame_left++;
    updateImageLeft(current_frame_left);

    forwardsLeftID = requestAnimationFrame(forwardsLeft);
  } else {
    cancelAnimationFrame(forwardsLeftID);
  }
}

function forwardsRight() {
  if(current_frame_right + 1 < frame_count_right) {
    current_frame_right++;
    updateImageRight(current_frame_right);

    forwardsRightID = requestAnimationFrame(forwardsRight);
  } else {
    cancelAnimationFrame(forwardsRightID);
  }
}




const header = document.querySelector('.header');
if(window.innerWidth <= 991) {
  const toggle = document.querySelector('#menu-toggle');

  if(toggle) {
    toggle.addEventListener('click', function() {
      toggle.classList.toggle('close');
      header.classList.toggle('open');
    });
  }
}

barba.init({
  views: [{
    namespace: 'home',
    beforeLeave(data) {
      header.classList.remove('header--home');
      document.querySelector('html').classList.remove('body-home');

    },
    beforeEnter(data) {

    },
    afterEnter(data) {
      initTypography();
      initCTAS();
      initHome();
      initHovers();

      document.querySelector('html').classList.add('body-home');

      header.classList.remove('hide');
      header.classList.add('header--home');
      document.querySelector('.cursor').classList.remove('hover');
    }
  },
    {
      namespace: 'signup',
      beforeLeave(data) {

        header.classList.remove('h-signup');

      },
      beforeEnter(data) {

      },
      afterEnter(data) {
        initTypography();
        initCTAS();
        initSignup();
        initHovers();

        header.classList.remove('black');
        header.classList.add('h-signup');
      }
    },
    {
      namespace: 'benefits',
      beforeLeave(data) {
        document.querySelector('.s-link').style.display = '';
      },
      beforeEnter(data) {

      },
      afterEnter(data) {
        initTypography();
        initCTAS();
        initSlider();
        initHovers();

        document.querySelector('.s-link').style.display = 'none';
      }
    },
    {
      namespace: 'card',
      beforeLeave(data) {
        if(scroller_section) {
          scroller_section.destroy();
        }
      },
      beforeEnter(data) {

      },
      afterEnter(data) {
        initTypography();
        initCTAS();
        initCard();
        initHovers();

        document.querySelector('.cursor').classList.remove('hover');
      }
    },
    {
      namespace: 'app',
      beforeLeave(data) {
        if(scroller_section) {
          scroller_section.destroy();
        }
      },
      beforeEnter(data) {

      },
      afterEnter(data) {
        initTypography();
        initCTAS();
        initApp();
        initHovers();

        document.querySelector('.cursor').classList.remove('hover');
      }
    }],
  transitions: [{
    name: 'drag-transition-card',
    to: {
      namespace: [
        'card'
      ]
    },
    once(data) {
      initImageSequence();
      canvas_right.style.display = 'none';
      canvas_left.style.display = 'block';
      updateImageLeft(frame_count_left);
    },
    beforeLeave(data) {

    },
    leave(data) {
      const done = this.async();

      if(current_frame_left < frame_count_left) {
        forwardsLeftID = requestAnimationFrame(forwardsLeft);
      }

      localStorage.setItem('cardScroll', 0);

      const left_content = document.querySelector('.home__slider__item.left .home__slider__item__content');
      const right_content = document.querySelector('.home__slider__item.right .home__slider__item__content');
      const drag = document.querySelector('.drag');

      gsap.to(left_content, {
        opacity: 0,
        duration: .6,
        ease: "expo.out"
      });
      gsap.to(right_content, {
        opacity: 0,
        duration: .6,
        ease: "expo.out"
      });

      gsap.to(drag, {
        opacity: 0,
        duration: 0.2,
        ease: "power1.inOut"
      });

      let delay = 1000;
      if(data.trigger == 'barba') delay = 200;

      setTimeout(function() {
        done();
      }, delay);
    },
    afterEnter(data) {
      var vids = document.querySelectorAll('video[autoplay]'); vids.forEach(vid => { var playPromise = vid.play(); if (playPromise !== undefined) { playPromise.then(_ => {}).catch(error => {}); }; });
    }
  },
    {
      name: 'drag-transition-app',
      to: {
        namespace: [
          'app'
        ]
      },
      once(data) {
        initImageSequence();
        canvas_right.style.display = 'block';
        canvas_left.style.display = 'none';
        updateImageRight(frame_count_right);
      },
      beforeLeave(data) {

      },
      leave(data) {
        const done = this.async();

        if(current_frame_right < frame_count_right) {
          forwardsRightID = requestAnimationFrame(forwardsRight);
        }

        localStorage.setItem('appScroll', 0);

        if(document.querySelector('.step1')) {
          document.querySelector('.step1').style.opacity = 0;
          document.querySelector('.step2').style.opacity = 0;
        }

        const left_content = document.querySelector('.home__slider__item.left .home__slider__item__content');
        const right_content = document.querySelector('.home__slider__item.right .home__slider__item__content');
        const drag = document.querySelector('.drag');

        canvas_right.style.display = 'block';
        canvas_left.style.display = 'none';

        gsap.to(left_content, {
          opacity: 0,
          duration: .6,
          ease: "expo.out"
        });
        gsap.to(right_content, {
          opacity: 0,
          duration: .6,
          ease: "expo.out"
        });

        gsap.to(drag, {
          opacity: 0,
          duration: 0.2,
          ease: "power1.inOut"
        });

        let delay = 1000;
        if(data.trigger == 'barba') delay = 200;

        setTimeout(function() {
          done();
        }, delay);
      },
      afterEnter(data) {
        var vids = document.querySelectorAll('video[autoplay]'); vids.forEach(vid => { var playPromise = vid.play(); if (playPromise !== undefined) { playPromise.then(_ => {}).catch(error => {}); }; });
      }
    },
    {
      name: 'back-transition',
      from: {
        namespace: [
          'card', 'app'
        ]
      },
      to: {
        namespace: [
          'home'
        ]
      },
      beforeLeave(data) {

      },
      leave(data) {
        const done = this.async();
        gsap.to(document.querySelector('.section-scroll'), {
          opacity: 0,
          duration: 0.4,
          onComplete: function() {
            scroller_section.destroy();
            header.classList.remove('black');
            s_link.classList.remove('black');

            if(current_frame_right > 0) {
              backwardsRightID = requestAnimationFrame(backwards);
            }

            if(current_frame_left > 0) {
              backwardsLeftID = requestAnimationFrame(backwards);
            }

            setTimeout(function() {
              done();
            }, 500);
          }
        });
      },
      beforeEnter(data) {


      },
      enter(data) {

      },
      afterEnter(data) {

      }
    }]
});


window.scroller_section = null;
window.scroll_detail = null;



window.initMotions = function() {
}

window.initSliders = function() {
  const slider_imgs = document.querySelectorAll('.slider-imgs');

  slider_imgs.forEach((slider) => {



    if(slider.classList.contains('inverse')) {
      let newH = slider.dataset.height;

      if(window.innerWidth <= 991) {
        newH = slider.dataset.heightMobile;
      }

      newH = parseInt(slider.querySelector('.slider-imgs__item.active').dataset.id) * newH;

      slider.querySelector('.sliderr').style.transform = 'translate3d(0, -' + newH + 'vh, 0)';
    }


    slider.addEventListener('click', function() {

      slider_imgs.forEach((s) => {
        const w = s.querySelector('.sliderr');
        let h = s.dataset.height;
        let i;

        if(window.innerWidth <= 991) {
          h = s.dataset.heightMobile;
        }

        const active = s.querySelector('.slider-imgs__item.active');


        if(s.classList.contains('inverse')) {
          if(active.previousElementSibling) {
            active.classList.remove('active');
            active.previousElementSibling.classList.add('active');
            i = active.previousElementSibling.dataset.id;
          } else {
            active.classList.remove('active');
            let el = s.querySelector('.slider-imgs__item:last-child');
            el.classList.add('active');
            i = el.dataset.id;
          }
        } else {
          if(active.nextElementSibling) {
            active.classList.remove('active');
            active.nextElementSibling.classList.add('active');
            i = active.nextElementSibling.dataset.id;
          } else {
            active.classList.remove('active');
            let el = s.querySelector('.slider-imgs__item:first-child');
            el.classList.add('active');
            i = el.dataset.id;
          }
        }

        w.style.transform = 'translate3d(0, -' + (i * h) + 'vh, 0)';

      });


    });
  });
}

window.initExpands = function() {

  const expands = document.querySelectorAll('.img-expand');

  expands.forEach((expand) => {
    expand.addEventListener('click', function(e) {
      e.preventDefault();

      header.classList.add('hide');

      if(document.querySelector('.has-scroll-smooth')) {
        scroller_section.stop();
        document.querySelector('.s-scrollbar').classList.add('disabled');
      }

      var that = this;

      setTimeout(function() {

        header.classList.add('hide');

        const detail = document.querySelector('#' + that.dataset.id);

        var offset = that.getBoundingClientRect();
        var top = offset.top;
        var left = offset.left;

        detail.style.top = top + 'px';
        detail.style.left = left + 'px';

        detail.dataset.top = top;
        detail.dataset.left = left;

        detail.classList.add('expanded');

        gsap.to(detail, {
          height: window.innerHeight + "px",
          width: window.innerWidth + "px",
          top: 0,
          left: 0,
          duration: 0.8,
          ease: "power2.out",
          onComplete: function() {
            if(window.innerWidth <= 991) {
              return false;
            }
            scroll_detail = new LocomotiveScroll({
              el: detail.querySelector('.scroller'),
              smooth: true,
              smartphone: {
                smooth: true,
              },
              tablet: {
                smooth: true,
              },
              touchMultiplier: 4,
            });

            setTimeout(function() {
              scroll_detail.update();
            }, 500);
          }
        });

      }, 500);

    });
  });




  const expand_btns = document.querySelectorAll('.img-detail__buttons button');

  expand_btns.forEach((btn) => {
    btn.addEventListener('click', function(e) {
      e.preventDefault();

      var action = this.dataset.id;

      this.parentElement.querySelectorAll('button').forEach((b) => {
        b.classList.remove('active');
      });

      this.classList.add('active');

      if(action == 'select') {
        scroll_detail.scrollTo('bottom');
        scroll_detail.el.classList.add('select');
        scroll_detail.el.parentElement.classList.add('select');
        scroll_detail.el.previousElementSibling.classList.add('animate');
      }

      if(action == 'front') {
        scroll_detail.scrollTo('top');
        scroll_detail.el.classList.remove('select');
        scroll_detail.el.parentElement.classList.remove('select');
        scroll_detail.el.previousElementSibling.classList.remove('animate');
      }
    });
  });


  const close_expands = document.querySelectorAll('.img-detail .close button');

  close_expands.forEach((btn) => {
    btn.addEventListener('click', function() {

      const detail = this.parentElement.parentElement;

      if(detail.querySelector('.img-detail__buttons')) {
        detail.querySelector('.img-detail__buttons button:first-child').classList.add('active');
        detail.querySelector('.img-detail__buttons button:last-child').classList.remove('active');

      }

      let w = detail.dataset.width;
      let h = detail.dataset.height;

      if (window.innerWidth <= 991) {
        w = detail.dataset.widthMobile;
        h = detail.dataset.heightMobile;
      }

      if(scroll_detail) {
        scroll_detail.el.classList.remove('select');
        scroll_detail.el.parentElement.classList.remove('select');
        scroll_detail.el.previousElementSibling.classList.remove('animate');

        scroll_detail.scrollTo('top', {
          duration: 0.2,
          callback: function() {
            detail.classList.remove('expanded');
            gsap.to(detail, {
              height: h + "vh",
              width: w + "vw",
              top: detail.dataset.top + "px",
              left: detail.dataset.left + "px",
              duration: 0.6,
              ease: "power2.out",
              onComplete: function() {
                if(document.querySelector('.has-scroll-smooth')) {
                  scroller_section.start();
                  document.querySelector('.s-scrollbar').classList.remove('disabled');
                }

                header.classList.remove('hide');
                scroll_detail.destroy();

                detail.style.top = '';
                detail.style.left = '';
              }
            });
          }
        });
      } else {
        detail.classList.remove('expanded');
        gsap.to(detail, {
          height: h + "vh",
          width: w + "vw",
          top: detail.dataset.top + "px",
          left: detail.dataset.left + "px",
          duration: 0.6,
          ease: "power2.out",
          onComplete: function() {
            if(document.querySelector('.has-scroll-smooth')) {
              scroller_section.start();
              document.querySelector('.s-scrollbar').classList.remove('disabled');
            }
            header.classList.remove('hide');

            detail.style.top = '';
            detail.style.left = '';
          }
        });
      }



    })
  });
}



// height
document.querySelector('html').style.setProperty('--height', `${window.innerHeight}px`);
document.querySelector('html').style.setProperty('--heightMobile', `${window.innerHeight}px`);

window.addEventListener('resize', () => {
  document.querySelector('html').style.setProperty('--height', `${window.innerHeight}px`);
});

const path = window.location.pathname.match(/^\/invitation\/(\w+)/);
if (path !== undefined && path !== null && path.length > 0 && path[0].indexOf('invitation') > -1) {
  window.sessionStorage.setItem('invitation', path[1]);
}