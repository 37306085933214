// libs
import LocomotiveScroll from 'locomotive-scroll';
import Pristine from 'pristinejs';

export default function initSignup() {
  const page = document.querySelector('.signup');
  const data = {};

  let path = null;
  path = window.location.pathname.match(/^\/invitation\/(\w+)/);
  if (path !== undefined && path !== null && path.length > 0 && path[0].indexOf('invitation') > -1) {
    data.referrer = path[1];
  }
  let ref = window.sessionStorage.getItem('invitation');
  if (ref !== undefined && ref !== null && ref.length > 0) {
    data.referrer = ref;
  }

  path = window.location.pathname.match(/^\/email-verification\/(.*)/);
  if (path !== undefined && path !== null && path.length > 0 && path[0].indexOf('email-verification') > -1) {
    data.referrer = path[1];
    document.querySelector('.form').classList.add('hide');
    document.querySelector('.email-verification').classList.add('show');
    let url = `${process.env.API_URL}email-verification/`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const method = 'POST';
    let body = {
      'code': path[1],
    };
    body = JSON.stringify(body);
    fetch(url, {method, headers, body})
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

      })
  }

  path = window.location.pathname.match(/^\/bcap/);
  if (path !== undefined && path !== null && path.length > 0 && path[0].indexOf('bcap') > -1) {
    data.referrer = "bcap";
  }


  path = window.location.pathname.match(/^\/nytech|/);
  if (path !== undefined && path !== null && path.length > 0 && path[0].indexOf('nytech') > -1) {
    data.referrer = "nytech";
  }

  path = window.location.pathname.match(/^\/era|/);
  if (path !== undefined && path !== null && path.length > 0 && path[0].indexOf('era') > -1) {
    data.referrer = "era";
  }


  if (data.hasOwnProperty('referrer')) {
    document.querySelector('.referer_code').value = data.referrer;
  }

  setTimeout(() => {
    scroller = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'), smooth: true,
    });

    page.classList.add('animate');
  }, 200);

  const switchBtn = document.querySelector('#switch');
  const body = document.querySelector('body');

  if (switchBtn) {
    switchBtn.addEventListener('change', () => {
      body.classList.toggle('light');
    });
  }

  const submit = document.querySelector('#submit');

  const inputs = document.querySelectorAll('.form-control[required]');

  inputs.forEach((input) => {
    input.addEventListener('keyup', () => {
      let valid = true;

      inputs.forEach((i) => {
        if (i.value == '') valid = false;
      });

      if (valid) {
        submit.disabled = false;
      }
    });
  });

  // form
  function validateForm(e) {
    e.preventDefault();
    const pristine = new Pristine(document.getElementById('apply'), {errorTextTag: 'span', errorClass: 'is-invalid'});
    if (pristine.validate()) {
      data.last_name = document.querySelector('.last_name').value;
      data.first_name = document.querySelector('.first_name').value;
      data.email = document.querySelector('.email').value;
      data.phone = document.querySelector('.phone').value;
      data.annual_income = document.querySelector('.income').value;
      data.zipcode = document.querySelector('.zip').value;
      data.referrer = document.querySelector('.referer_code').value;
      sendForm(data);
    }
  }

  function sendForm(data) {
    const url = `${process.env.API_URL}`;
    const method = 'POST';
    const body = JSON.stringify(data);
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const share_title = 'Excited about the @SelectCard credit card! Check it out and get on the waitlist for access during the invitation-only Beta.';
    const submitBtn = document.querySelector('#submit');
    submitBtn.innerHTML = 'Sending...';
    fetch(url, {method, headers, body})
      .then((response) => response.json())
      .then((response) => {
        document.querySelector('.share-facebook').setAttribute('href', `http://www.facebook.com/sharer.php?u=${response.referer_link}&t=${share_title}`);
        document.querySelector('.share-linkedin').setAttribute('href', `http://www.linkedin.com/shareArticle?mini=true&url=${response.referer_link}&title=${share_title}`);
        document.querySelector('.share-twitter').setAttribute('href', `http://twitter.com/share?url=${response.referer_link}&text=${share_title}`);
        document.querySelector('.order-number').innerHTML = response.ranking_position;
        document.querySelector('#invite-link').value = response.referer_link;

        document.querySelector('.form').classList.add('hide');
        document.querySelector('.confirmation').classList.add('show');
      })
      .catch((error) => {
        console.error('Error:', error);
        submitBtn.innerHTML = 'Submit';
      });
  }

  document.querySelector('#apply').addEventListener('submit', validateForm);

  function copyInviteLink() {
    // Get the text field

    const copyText = document.querySelector('#invite-link');

    // Select the text field
    copyText.focus();
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    const linkText = document.querySelector('.invite-link-text');
    linkText.innerHTML = 'Copied!';

    setTimeout(() => {
      linkText.innerHTML = 'Copy invite link';
    }, 2000);
  }

  const invite = document.querySelectorAll('.invite-link');

  for (let i = 0; i < invite.length; i++) {
    invite[i].addEventListener('click', copyInviteLink);
    console.log(invite[i]);
  }
}
